import { Text, VStack, Image as ChakraImage, HStack,useMediaQuery } from "@chakra-ui/react";
import React, { useContext } from "react";
import PostHeader from "./PostHeader";
import PostFooter from "./PostFooter";
import styles from "./TrialRoom.module.css";
import { AppContext } from "../context/AppContext";
import "@splidejs/react-splide/css";
import { themePrimaryColor } from "../utils/theme";
const TrialPost = ({
  imageUrls = [],
  userData = {},
  caption,
  likesCount = 0,
  sharesCount = 0,
  commentsCount = 0,
  postId,
  isLikedByMe,
  title,
  isTrial,
  postTime
}) => {
  const { setPostModalData, setTrialModalOpen, setCurrentPostCommentCount,setCommentModalOpen } =
    useContext(AppContext);
  const [isLargerThan766] = useMediaQuery("(min-width: 766px)");
  const handlePostModal = (isCommentModal=false) => {
    !isCommentModal&&setTrialModalOpen(true);
    setPostModalData({
      title,
      caption,
      likesCount,
      sharesCount,
      commentsCount,
      postId,
      imageUrls,
      userData,
      isLikedByMe,
      isTrial,
      postTime
    });
    setCurrentPostCommentCount(Number(commentsCount) || 0);
  };

  return (
    <VStack
      align={"start"}
      p={"1rem 0"}
      w={["95%", "90%", "75%", "60%"]}
      borderBottom={"1px solid black"}
      borderTop={"1px solid black"}
    >
      <PostHeader
        postId={postId}
        fullName={userData.fullName}
        email={userData.email}
        role={userData.role}
        HStack
        profilePicture={userData.profilePicture}
        isLikedByMe={isLikedByMe}
        userId={userData.userId}
        isTrial={isTrial}
        postTime={postTime}
      />
      <VStack
        justify={"center"}
        gap={"0.4rem"}
        w={["100%"]}
        h={"100%"}
        align={"center"}
      >
        <Text
          className={styles.PlatypiFont}
          textDecoration={"1px solid rgb(212,212,212) underline"}
          fontSize={"1.3rem"}
          fontWeight={600}
          color={themePrimaryColor}
        >
          {title}
        </Text>{" "}
        <Text className={styles.PlatypiFont} fontSize={"0.9rem"} lineHeight={'1.15rem'}>
          {caption}
        </Text>{" "}
        <HStack w={"100%"}  maxH={"78vh"} justify={"center"}>
          <ChakraImage
            maxH={"78vh"}
            onClick={()=>handlePostModal()}
            maxW={"100%"}
            objectFit={"cover"}
            src={imageUrls[0]}
            cursor={'pointer'}
          />
        </HStack>
      </VStack>
      {!isLargerThan766 ? (
                <PostFooter
                  setCommentModalOpen={setCommentModalOpen}
                  isLikedByMe={isLikedByMe}
                  postId={postId}
                  likesCount={likesCount}
                  sharesCount={sharesCount}
                  commentsCount={Number(commentsCount)}
                  handlePostModal={handlePostModal}
                />
        ):
        <PostFooter
          handlePostModal={handlePostModal}
          isLikedByMe={isLikedByMe}
          postId={postId}
          likesCount={likesCount}
          sharesCount={sharesCount}
          commentsCount={commentsCount}
        />
        }
    </VStack>
  );
};

export default TrialPost;
