import { VStack, useDisclosure, Image as ChakraImage } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import Post from "../../components/Post";
import AddTimeLinePost from "../../components/AddTimeLinePost";
import UserProfileCard from "../../components/UserProfileCard";

const Profile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [followerOrFollowing, setFollowerOrFollowing] = useState("");
  const dispatch = useDispatch();
  const {
    loading: ProfileLoading,
    error: ProfileError,
    profileData,
    myFollower,
    myFollowing,
  } = useSelector((store) => store.userData);
  const { Token } = useSelector((store) => store.authData);
  const [isLoaded, setIsLoaded] = useState(false);
  const { getUserPosts, get_all_posts_error, get_posts_by_user_loading } =
    useSelector((store) => store.postData);
  useEffect(() => {
    if (get_posts_by_user_loading) {
      setTimeout(() => {
        setIsLoaded(true);
      }, 3000);
    }
  }, [get_posts_by_user_loading]);
  useEffect(() => {
    console.log(myFollower, myFollowing);
  }, [myFollower, myFollowing]);

  useEffect(() => {
    console.log(getUserPosts,'getUserPosts');
  }
  , [dispatch, getUserPosts]);
  return (
    <VStack w={["100%", "100%", "87%", "78%"]} gap={0} pb={"1rem"}>
      <UserProfileCard
        isMe={true}
        followings={myFollowing}
        followers={myFollower}
        userData={profileData}
      />
      <AddTimeLinePost />
      {getUserPosts?.map((post,index) => (
        <Post
          key={v4()}
          leftOrRight={index%2==0}
          isLoaded={isLoaded}
          videoUrl={post.videoUrl}
          isLikedByMe={post.isLikedByMe}
          caption={post.caption}
          userData={post.userData}
          postId={post.id}
          imageUrls={post.imageUrls?.filter((el) => el.length)}
          likesCount={post.likesCount ?? 0}
          sharesCount={post.sharesCount}
          commentsCount={post.commentsCount}
          postTime={post.createdAt}
        />
      ))}
    </VStack>
  );
};

export default Profile;
