import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Image as ChakraImage,
  VStack,
  Spinner,
  useMediaQuery,
  Text,
  Box,
} from "@chakra-ui/react";
import { Md360 } from "react-icons/md";
import React, { useContext, useEffect, useState } from "react";
import PostHeader from "./PostHeader";
import IndividualComment from "./IndividualComment";
import PostFooter from "./PostFooter";
import CommentInput from "./CommentInput";
import { useDispatch, useSelector } from "react-redux";
import { getCommentsByUser } from "../store/comment/comment.action";
import { AppContext } from "../context/AppContext";
import { themePrimaryColor } from "../utils/theme";
import CommentsModal from "./CommentsModal";
import { ReactImageTurntable } from "react-image-turntable";
import { v4 } from "uuid";
import styles from "./TrialRoom.module.css";
const TrialModal = () => {
  const dispatch = useDispatch();
  const { Token ,isAdmin,AdminToken} = useSelector((store) => store.authData);
  const [rotationDisabled, setRotationDisabled] = useState(false);
  const [visible360, setVisible360] = useState(true);
  const {
    trialModalOpen: handlePostModal,
    setTrialModalOpen: setHandlePostModal,
    postModalData,
    currentPostCommentCount,
    setCommentModalOpen
  } = useContext(AppContext);
  const {
    caption = "",
    imageUrls = [],
    userData = {},
    likesCount = 0,
    sharesCount = 0,
    commentsCount = 0,
    postId = 0,
    isLikedByMe,
    isTrial,
    title,
    postTime
  } = postModalData;
  const { get_all_comments_loading: commentLoading, allComments } = useSelector(
    (store) => store.commentData
  );
  const [isLargerThan766] = useMediaQuery("(min-width: 766px)");
  const ClosePostModal = () => {
    setHandlePostModal(false);
  };
  useEffect(() => {
    if (handlePostModal) {
      dispatch(getCommentsByUser(isAdmin?AdminToken:Token, postId));
    }
  }, [postModalData]);

  const handleKeyDown = (ev) => {
    if (rotationDisabled) return;
    if (ev.key === "ArrowLeft" || ev.key === "ArrowRight") {
      setRotationDisabled(true);
    }
  };

  return (
    <HStack>
      <Modal
        onClose={ClosePostModal}
        isOpen={handlePostModal}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent h={"94vh"} minW={["97%", "97%", "93%", "80% !important"]}>
          <ModalHeader
            textAlign={"center"}
            borderBottom={"0.2px solid #f5f1f1"}
          ></ModalHeader>
          <ModalCloseButton
            onClick={() => setHandlePostModal(false)}
            background={"white !important"}
          />
          <ModalBody w={"100%"}>
            <HStack
              w={"100%"}
              h={"100%"}
              flexDirection={["column", "column", "row", "row"]}
              align={["center", "center", "start", "start"]}
            >
              <HStack
                justify={"center"}
                w={["100%", "100%", "65%", "65%"]}
                h={"100%"}
                align={"center"}
              >
                <VStack w={"100%"} h={"100%"}>
                  <Text
                    className={styles.PlatypiFont}
                    textDecoration={"1px solid rgb(212,212,212) underline"}
                    fontSize={"1.3rem"}
                    fontWeight={600}
                    color={themePrimaryColor}
                  >
                    {title}
                  </Text>

                  <HStack w={"100%"} maxH={"80vh"} justify={"center"}>
                    <VStack
                      h={["75vh", "75vh", "60vh", "60vh"]}
                      zIndex={10000}
                      onClick={() => setVisible360(false)}
                      position={"relative"}
                    >
                      {visible360 && (
                        <Box
                          as={Md360}
                          position={"absolute"}
                          cursor={"pointer"}
                          fontSize={["8rem", "10rem", "13rem", "15rem"]}
                          top={["10%", "15%", "30%", "35%"]}
                          zIndex={1000}
                          color={"rgb(212 212 212 / 41%)"}
                        />
                      )}
                      <ReactImageTurntable
                        images={imageUrls}
                        autoRotate={{
                          disabled: rotationDisabled,
                          interval: 200000,
                        }}
                        onPointerDown={() => setRotationDisabled(true)}
                        onPointerUp={() => setRotationDisabled(true)}
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyDown}
                        key={v4()}
                        style={{
                          maxHeight: "80vh",
                          maxWidth: isLargerThan766 ? "80%" : "98%",
                        }}
                      />
                    </VStack>
                  </HStack>
                </VStack>
              </HStack>
              {!isLargerThan766 && (
                <PostFooter
                  setCommentModalOpen={setCommentModalOpen}
                  isLikedByMe={isLikedByMe}
                  h={"6vh"}
                  postId={postId}
                  likesCount={likesCount}
                  sharesCount={sharesCount}
                  commentsCount={currentPostCommentCount}
                />
              )}
              <HStack
                display={["none", "none", "flex", "flex"]}
                align={"start"}
                h={"89vh"}
                w={"35%"}
              >
                <VStack
                  justify={"space-between"}
                  pt={"1rem"}
                  w={["100%"]}
                  h={"100%"}
                >
                  <PostHeader
                    h={"6vh"}
                    fullName={userData.fullName}
                    email={userData.email}
                    postId={postId}
                    role={userData.role}
                    isLikedByMe={isLikedByMe}
                    profilePicture={userData.profilePicture}
                    isTrial={isTrial}
                    userId={userData.userId}
                    postTime={postTime}
                  />
                  <VStack
                    position={"relative"}
                    overflowY={"auto"}
                    borderY={"0.1px solid grey"}
                    w={"100%"}
                    height={"50vh"}
                  >
                    <IndividualComment
                      isContent={true}
                      userName={userData.email}
                      comment={caption}
                      profilePicture={userData.profilePicture}
                      fullName={userData.fullName}
                    />
                    {commentLoading && (
                      <Spinner
                        position={"absolute"}
                        top={"50%"}
                        size={"lg"}
                        thickness="3px"
                        color={themePrimaryColor}
                      />
                    )}
                    {allComments?.map((comment) => (
                      <IndividualComment
                        postId={postId}
                        commentId={comment.id}
                        userName={comment.userData.email}
                        comment={comment.content}
                        fullName={comment.userData.fullName}
                        profilePicture={comment.userData.profilePicture}
                      />
                    ))}
                  </VStack>
                  <PostFooter
                    isLikedByMe={isLikedByMe}
                    h={"6vh"}
                    postId={postId}
                    likesCount={likesCount}
                    sharesCount={sharesCount}
                    commentsCount={currentPostCommentCount}
                  />
                  <CommentInput
                    h={"10vh"}
                    setHandlePostModal={setHandlePostModal}
                    postId={postId}
                  />
                </VStack>
              </HStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>

    </HStack>
  );
};

export default TrialModal;
