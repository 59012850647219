import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import LeftSideBar from "../../components/LeftSideBar.jsx";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MainProfile from "../../components/MainProfile";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../store/user/user.action.js";
import PostModal from "../../components/PostModal.jsx";
import { AppContext } from "../../context/AppContext.jsx";
import { FaBars } from "react-icons/fa6";
import { themePrimaryColor } from "../../utils/theme.js";
import { IoClose } from "react-icons/io5";
import TrialModal from "../../components/TrialModal.jsx";
import CommentsModal from "../../components/CommentsModal.jsx";

const Home = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLargerThan766] = useMediaQuery("(min-width: 766px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showMenuHamburger, setShowMenuHamburger } = useContext(AppContext);

  useEffect(() => {
    if (!showMenuHamburger && !isLargerThan766) {
      setShowMenuHamburger(true);
    }
  }, [location.pathname]);
  return (
    <HStack gap={"0"} align={"start"} minH={'100vh'} bgColor={'#F9FAFB'}>
      {!isLargerThan766 && showMenuHamburger ? (
        <Box
          zIndex={1000}
          border={"0.1px solid rgb(168, 168, 168)"}
          display={"flex"}
          onClick={onOpen}
          justifyContent={"center"}
          fontSize={"1.3rem"}
          alignItems={"center"}
          borderRadius={"0.2rem"}
          bgColor={themePrimaryColor}
          w={"2rem"}
          h={"2rem"}
          color={"white"}
          position={"fixed"}
          bottom={"1rem"}
          right={"1rem"}
        >
          <Box as={FaBars}></Box>
        </Box>
      ) : !showMenuHamburger ? (
        <></>
      ) : (
        <LeftSideBar />
      )}
      <PostModal />
      <TrialModal />
      <CommentsModal/>
      {location.pathname === "/" && <MainProfile />}

      <Outlet />
      <Drawer placement={"left"} onClose={onClose} size={"xs"} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerHeader borderBottomWidth='1px'>Basic Drawer</DrawerHeader> */}
          <DrawerBody position={"relative"}>
            <Box
              as={IoClose}
              onClick={onClose}
              color={"black"}
              fontSize={"1.3rem"} 
              top={"0.6rem"}
              zIndex={1000}
              right={"0.5rem"}
              position={"absolute"}
            />
            <LeftSideBar onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </HStack>
  );
};

export default Home;
