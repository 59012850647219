import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaHeart, FaRegCommentAlt } from "react-icons/fa";
import { GrLike } from "react-icons/gr";
import { LuShare2 } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { doLikeApi, doUnlikeApi } from "../store/post/post.action";
import { LIKE_POST_ERROR, UNLIKE_POST_ERROR } from "../store/post/post.types";
import { AppContext } from "../context/AppContext";
import { FaCopy } from "react-icons/fa";
import { themePrimaryColor } from "../utils/theme";
import { IoClose } from "react-icons/io5";
import { baseurl } from "../utils/api";
import { FaCheck } from "react-icons/fa";
import { getCommentsByUser } from "../store/comment/comment.action";
import { MdOpenInNew } from "react-icons/md";
import { GoHeart ,GoHeartFill} from "react-icons/go";
import { motion } from "framer-motion";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
} from "react-share";

const MotionBox = motion(Box);

const PostFooter = ({
  likesCount,
  commentsCount,
  sharesCount,
  postId,
  handlePostModal,
  h = "auto",
  isLikedByMe = false,
  setCommentModalOpen,
  caption = ""
}) => {
  const { Token, isAdmin, AdminToken } = useSelector((store) => store.authData);
  const { profileData } = useSelector((store) => store.userData);
  const { like_loading } = useSelector((store) => store.postData);
  const MY_URL = window.location.href.split('/').slice(0, 3).join('/')
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isCopied, setIsCopied] = useState(false);
  const [isLiked, setIsLiked] = useState(isLikedByMe);
  const [likeCount, setLikeCount] = useState(likesCount);
  const dispatch = useDispatch();
  const [sharePopup, setSharePop] = useState(false);
  const containerRef = useRef(null);
  const toast = useToast();
  const [isLargerThan766] = useMediaQuery("(min-width: 766px)");
  const [showAnimation, setShowAnimation] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  const handleLikePost = () => {
    if (!isLiked) {
      setShowAnimation(true);
      setIsLiked(true);
      setLikeCount((pre) => Number(pre) + 1);
      setAnimationKey(prev => prev + 1);
      dispatch(doLikeApi(Token, { userId: profileData.id, postId })).then(
        (res) => {
          if (res.type == LIKE_POST_ERROR) {
            setIsLiked(false);
            setLikeCount((pre) => Number(pre) - 1);
            setTimeout(() => {
              setShowAnimation(false);
            }, 1000);        
          }
        }
      );
    } else {
      setIsLiked(false);
      setLikeCount((pre) => Number(pre) - 1);
      dispatch(doUnlikeApi(Token, { userId: profileData.id, postId })).then(
        (res) => {
          if (res.type == UNLIKE_POST_ERROR) {
            setIsLiked(true);
            setLikeCount((pre) => Number(pre) + 1);
          }
        }
      );
    }
  };
  const shareUrl = `${MY_URL}/post/${postId}`;
  const CopyToClipBoard = () => {
    console.log("ram here");
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1200);
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
        toast({
          status: "error",
          title: `error in copying url`,
          isClosable: true,
          duration: 1000,
        });
      });
  };
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSharePop(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <HStack
      p={"0 0.3rem"}
      w={"100%"}
      h={h}
      justify={"space-between"}
      gap={"3rem"}
      position={"relative"}
    >
      <VStack>
      {showAnimation && (
        <MotionBox
        key={animationKey}
          animate={{
            y: ["0rem", "-0.5rem", "-1rem", "-1.5rem", "-2rem"],
            x: ["0rem", "0.5rem", "-0.5rem", "0.5rem", "-0.5rem"],
            opacity: [1, 0.8, 0.6, 0.4, 0]
          }}
          initial={{opacity:'1'}}
          transition={{ duration: 0.8 }}
          position={'absolute'}
          bottom={"2rem"}
          left={"0"}
        >
          <Box color={'#df1212e0'}   fontSize={["1.1rem", "1.2rem", "1.3rem", "1.5rem"]} as={GoHeartFill}/>
        </MotionBox>
      )}

        <Box
          as={isLiked?GoHeartFill:GoHeart}
          cursor={like_loading ? "cell" : "pointer"}
          fontSize={["1.1rem", "1.2rem", "1.3rem", "1.5rem"]}
          color={isLiked ? "#df1212e0" : "black"}
          onClick={() => {
            if (!like_loading) {
              handleLikePost();
            }
          }}
        />
        {/* <GrLike onClick={()=>{
            if(!like_loading){
                handleLikePost();
            }
        }}  style={{cursor:like_loading?"cell":'pointer',fontSize:'1.5rem',color:isLiked?'#3879c6':'black'}}/> */}
        <Box as="span" color={"#6e6e6e"} fontSize={"0.9rem"}>
          {likeCount}
        </Box>
      </VStack>
      <VStack>
        <Box
          as={FaRegCommentAlt}
          fontSize={["1.1rem", "1.2rem", "1.3rem", "1.5rem"]}
          cursor={"pointer"}
          onClick={() => {
            if (!isLargerThan766) {
              dispatch(getCommentsByUser(isAdmin ? AdminToken : Token, postId));
              console.log('clicked');
              setCommentModalOpen?.(true)
            };
            console.log(handlePostModal, 'checking ahdnlePost')
            if (handlePostModal) handlePostModal(!isLargerThan766);
          }}
        />
        <Box as="span" color={"#6e6e6e"} fontSize={"0.9rem"}>
          {commentsCount}
        </Box>
      </VStack>
      <VStack>
        <Box
          as={LuShare2}
          cursor={"pointer"}
          onClick={() => {
            setSharePop(true);
          }}
          fontSize={["1.1rem", "1.2rem", "1.3rem", "1.5rem"]}
        />
        {/* <LuShare2 style={{cursor:'pointer',fontSize:'1.5rem'}}/> */}
      </VStack>
      {sharePopup && (
        
        <MotionBox
          animate={{
            y: sharePopup ? "-1rem" : "0rem",
          }}
          transition={{ duration: 0.2 }}
          position={'absolute'}
          bottom={"1rem"}
          right={"0"}
        >
          <HStack
            rounded={"0.2rem"}
            background={"white"}
            // position={"absolute"}
            ref={containerRef}
            p={"0.5rem"}
            border={"1px solid rgb(168, 168, 168)"}
            zIndex={1}
          >
            <Box as={MdOpenInNew} cursor={'pointer'} fontSize={'1.8rem'} onClick={CopyToClipBoard} />
            <HStack>
              <WhatsappShareButton url={shareUrl} title={caption}>
                <WhatsappIcon size={30} round={true} />
              </WhatsappShareButton>
              <FacebookShareButton url={shareUrl} title={caption}>
                <Box as={FacebookIcon} size={30} rounded={'50%'} fontSize={'1rem'} />
              </FacebookShareButton>
              {/* <Box
              onClick={CopyToClipBoard}
              cursor={"pointer"}
              color={isCopied ? "green" : themePrimaryColor}
              as={isCopied ? FaCheck : FaCopy}
            />
            <Box
              cursor={"pointer"}
              as={IoClose}
              onClick={() => setSharePop(false)}
            /> */}
            </HStack>
          </HStack>
        </MotionBox>
      )}
    </HStack>
  );
};

export default PostFooter;
