import {
  Avatar,
  Box,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { themePrimaryColor } from "../utils/theme";
import { RoleName } from "../utils/constant";
import { FaSearch } from "react-icons/fa";
import { AppContext } from "../context/AppContext";
import {
  collection,
  doc,
  documentId,
  getDoc,
  onSnapshot,
  query,
  where,
  FieldValue,
} from "firebase/firestore";
import { db } from "../firebaseFile";
import { useSelector } from "react-redux";

const ChatAllUsers = ({ handlePersonChat,setShowChat }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [isLargerThan769] = useMediaQuery("(min-width: 769px)");
  const { allUsers: ourAllUsers, profileData } = useSelector(
    (store) => store.userData
  );
  const [allRoomIds, setAllRoomIds] = useState([]);
  const { firebaseUserId: user ,setShowMenuHamburger,currentChatUser,
    setCurrentChatUser} = useContext(AppContext);
  useEffect(() => {
    setUsers();
  }, [user]);

  useEffect(() => {
    if (allRoomIds?.length > 0) {
      allRoomIds.forEach((roomid) => {
        const q = query(
          collection(db, "chats"),
          where(documentId(), "==", roomid)
        );
        onSnapshot(q, (qSnapshot) => {
          qSnapshot.docs.forEach((doc) => {
            let roomDetail = doc.data();
            let finalData = allUsers.map((item) => {
              let findUser = ourAllUsers.find(
                (ourUser) => (ourUser.email === item.data.email&&profileData.email!==item.data.email)
              );
              if (findUser) {
                item.data.displayName = findUser.fullName;
                item.data.profile_pictures = findUser.profilePicture;
                item.data.role = findUser.role;
                item.data.userId = findUser.id;
              }else{
                return undefined;
              }
              if (item.data.roomIdWithMe === roomid) {
                item.data.roomDetail = roomDetail;
                return item;
              } else {
                return item;
              }
            });
            finalData = finalData.filter((item)=>item);
            const arrUniq = [
              ...new Map(finalData.map((v) => [v.data.uid, v])).values(),
            ];
            setAllUsers(arrUniq);
          });
        });
      });
    }
  }, [allRoomIds]);
  const setUsers = async () => {
    if (user.uid) {
      const q = query(
        collection(db, "users"),
        where(documentId(), "!=", user?.uid)
        // where('hasChattedWithCurrentUser', '==', true)
      );
      onSnapshot(q, (querySnapshot) => {
        setAllUsers(
          querySnapshot.docs.map((document) => {
            let roomid = [user.uid, document.data().uid].sort();
            roomid = roomid[0] + roomid[1];
            setAllRoomIds((oldArray) => [...oldArray, roomid]);
            const data = document.data();
            data.roomIdWithMe = roomid;
            let findUser = ourAllUsers.find(
              (ourUser) => (ourUser.email === data.email&&ourUser.email!==profileData.email)
            );
            if (findUser) {
              data.displayName = findUser.fullName;
              data.profile_pictures = findUser.profilePicture;
              data.role = findUser.role;
              data.userId = findUser.id;
            }else{
              return undefined;
            }
            return { data };
          }).filter((el)=>el)
        );
      });
    }
  };
  useEffect(()=>{
    if(currentChatUser.length&&allUsers.length){
      let findUser = allUsers?.find((user)=>user?.data?.email===currentChatUser);
      if(findUser){
        handlePersonChat(findUser);
      }
      if(!isLargerThan769){
        setShowChat(true)
        setShowMenuHamburger(false);
      }
      setCurrentChatUser('');
    }
  },[currentChatUser,allUsers])
  return (
    <VStack  align={"start"} h={"90vh"} p={'0.2rem'} w={["100%", "100%", "33%", "25%"]}>
      <Text fontSize={"1.3rem"}>Messages</Text>
      {/* <InputGroup size="md">
        <Input
          pr="4.5rem"
          type={"text"}
          placeholder="Search user"
          // onChange={handleChange}
        />
        <InputRightElement>
          <Box
            as={FaSearch}
            color={themePrimaryColor}
            //   cursor={"pointer"}
          ></Box>
        </InputRightElement>
      </InputGroup> */}
      <VStack w={"100%"} bgColor={'white'} overflowY={"auto"} h={"84vh"} overflowX={"hidden"}>
        {allUsers?.map((item, index) => {
          const unreadCount = item?.data?.roomDetail
            ? typeof item?.data?.roomDetail[user?.uid]?.unread_count ===
              "number"
              ? item?.data?.roomDetail[user?.uid]?.unread_count
              : 0
            : 0;
          return (
            <HStack
              cursor={"pointer"}
              _hover={{ bgColor: "#f1ecec4f" }}
              p={"0.2rem"}
              w={"100%"}
              border={`1px solid ${themePrimaryColor}`}
              rounded={"0.2rem"}
              justify={"start"}
              justifyContent={"space-between"}
              onClick={() => {
                handlePersonChat(item);
                if(!isLargerThan769){
                  setShowChat(true)
                  setShowMenuHamburger(false);
                }
              }}
            >
              <HStack>
                <Avatar alt="" src={item?.data?.profile_pictures} />
                <VStack align={"start"}>
                  <Text fontWeight={600}>{item?.data?.displayName}</Text>
                  <Text
                    fontWeight={500}
                    fontSize={"0.8rem"}
                    color={"rgb(168, 168, 168)"}
                  >
                    {RoleName(item?.data?.role)}
                  </Text>
                </VStack>
              </HStack>
              <Box
                as="span"
                color={unreadCount > 0 ? "red" : "blue"}
                borderRadius={"50%"}
                w={"1.2rem"}
                h={"1.2rem"}
                fontSize={"0.9rem"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {unreadCount}
              </Box>
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
};

export default ChatAllUsers;
