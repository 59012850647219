import axios from "axios";
import { DELETE, GET, POST, UPDATE, apiRequest, baseurl } from "../../utils/api";
import { CREATE_POST_ERROR,CREATE_POST_LOADING,CREATE_POST_SUCCESS, DELETE_POST_ERROR, DELETE_POST_LOADING, DELETE_POST_SUCCESS, GET_POSTS_BY_ANOTHER_USER_ERROR, GET_POSTS_BY_ANOTHER_USER_LOADING, GET_POSTS_BY_ANOTHER_USER_SUCCESS, GET_POSTS_BY_USER_ERROR, GET_POSTS_BY_USER_LOADING, GET_POSTS_BY_USER_SUCCESS, GET_POSTS_ERROR, GET_POSTS_LOADING, GET_POSTS_SUCCESS, GET_SINGLE_POST_ERROR, GET_SINGLE_POST_LOADING, GET_SINGLE_POST_SUCCESS, GET_TRENDING_POSTS_ERROR, GET_TRENDING_POSTS_LOADING, GET_TRENDING_POSTS_SUCCESS, GET_TRIAL_POSTS_ERROR, GET_TRIAL_POSTS_LOADING, GET_TRIAL_POSTS_SUCCESS, LIKE_POST_ERROR, LIKE_POST_LOADING, LIKE_POST_SUCCESS, UNLIKE_POST_ERROR, UNLIKE_POST_LOADING, UNLIKE_POST_SUCCESS } from "./post.types";
import { unAuthorisedApi } from "../auth/auth.action";
import { getAllUsersBehalfId, getObjectsWithUniqueUserIds } from "../../utils/utils";

export const getPostByUser = (token,userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_POSTS_BY_USER_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/postsByUser/${userId}`, token });
    const likedResposne = await apiRequest({ type: GET, endpoint: `posts/likespostById/${userId}`, token });
    likedResposne.data.data.forEach(likedPost => {
      const postIndex = res.data.data.findIndex(post => post.id === likedPost.id);
      if (postIndex !== -1) {
        res.data.data[postIndex].isLikedByMe = true;
      }
    });
    let usersInfoAddedInPostData = res.data.data.map(item => {
      const userData = {
        userId: item.userId,
        email:item?.userName??'',
        role:item?.role,
        fullName:item?.fullName,
        profilePicture:item?.profilePicture
      }
      return {
          ...item,
          userData: userData
      };
    });
    usersInfoAddedInPostData = usersInfoAddedInPostData.filter((el)=>!el.isTrial)
    return dispatch({ type: GET_POSTS_BY_USER_SUCCESS, payload: usersInfoAddedInPostData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_POSTS_BY_USER_ERROR,
      payload: err
    });
  }
};
export const getAllPosts = (token,userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_POSTS_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/getAllFollowingPost/${userId}`, token });
    const likedResposne = await apiRequest({ type: GET, endpoint: `posts/likespostById/${userId}`, token });
    likedResposne.data.data.forEach(likedPost => {
      const postIndex = res.data.data.findIndex(post => post.id === likedPost.id);
      if (postIndex !== -1) {
        res.data.data[postIndex].isLikedByMe = true;
      }
   });
    let usersInfoAddedInPostData = res.data.data.map(item => {
      const userData = {
        userId: item.followerId,
        email:item?.followerName??'',
        role:item?.role,
        fullName:item?.fullName,
        profilePicture:item?.profilePicture
      }
      return {
          ...item,
          userData: userData,
          userId:item.followerId
      };
    });
    usersInfoAddedInPostData = usersInfoAddedInPostData.filter((el)=>!el.isTrial)
    return dispatch({ type: GET_POSTS_SUCCESS, payload: usersInfoAddedInPostData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_POSTS_ERROR,
      payload: err
    });
  }
};
export const getAdminAllPost = (token,userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_POSTS_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/getAllPost`, token });
    const likedResposne = await apiRequest({ type: GET, endpoint: `posts/likespostById/${userId}`, token });
    likedResposne.data.data.forEach(likedPost => {
      const postIndex = res.data.data.findIndex(post => post.id === likedPost.id);
      if (postIndex !== -1) {
        res.data.data[postIndex].isLikedByMe = true;
      }
   });
    let usersInfoAddedInPostData = res.data.data.map(item => {
      const userData = {
        userId: item.userId,
        email:item?.userName??'',
        role:item?.role,
        fullName:item?.fullName,
        profilePicture:item?.profilePicture
      }
      return {
          ...item,
          userData: userData,
          userId:item.followerId
      };
    });
    usersInfoAddedInPostData = usersInfoAddedInPostData.filter((el)=>!el.isTrial)
    return dispatch({ type: GET_POSTS_SUCCESS, payload: usersInfoAddedInPostData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_POSTS_ERROR,
      payload: err
    });
  }
};
export const getSinglePost = (token,postId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_POST_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/postById/${postId}`, token });
    let post = res.data?.data;
    if(!post?.userId){
      throw new Error("UserId Not Found!");
    };
    const likedResposne = await apiRequest({ type: GET, endpoint: `posts/likespostById/${post.userId}`, token });
    likedResposne.data.data.forEach(likedPost => {
      if (likedPost.id==postId) {
        res.data.data.isLikedByMe = true;
      }
   });
    let userData = {
      email:post.userName,
      fullName:post.fullName,
      userId:post.userId,
      role:post.role,
      profilePicture:post.profilePicture
    }
    post.userData = userData;
    return dispatch({ type: GET_SINGLE_POST_SUCCESS, payload: post});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    console.log(err,'singlePost error here');
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_SINGLE_POST_ERROR,
      payload: err
    });
  }
};
export const getTrendingPosts = (token,userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_TRENDING_POSTS_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/trending`, token });
    const uniqueUserIds = getObjectsWithUniqueUserIds(res.data.data);
    const uniqueUsers = await getAllUsersBehalfId(uniqueUserIds,token);
    const likedResposne = await apiRequest({ type: GET, endpoint: `posts/likespostById/${userId}`, token });
    likedResposne.data.data.forEach(likedPost => {
      const postIndex = res.data.data.findIndex(post => post.id === likedPost.id);
      if (postIndex !== -1) {
        res.data.data[postIndex].isLikedByMe = true;
      }
   });
    const uniqueUserDataMap = {};
    uniqueUsers.forEach(user => {
      uniqueUserDataMap[user.id] = {
            email: user.email,
            profilePicture: user.profilePicture,
            role: user.role,
            fullName:user.fullName,
            userId:user.id
        };
    });
    let usersInfoAddedInPostData = res.data.data.map(item => {
      const userData = uniqueUserDataMap[item.userId];
      return {
          ...item,
          userData: userData
      };
    });
    usersInfoAddedInPostData = usersInfoAddedInPostData.filter((el)=>!el.isTrial)
    return dispatch({ type: GET_TRENDING_POSTS_SUCCESS, payload: usersInfoAddedInPostData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_TRENDING_POSTS_ERROR,
      payload: err
    });
  }
};
export const getTrialPosts = (token,userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_TRIAL_POSTS_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/getAllTrials/${userId}`, token });
    const likedResposne = await apiRequest({ type: GET, endpoint: `posts/likespostById/${userId}`, token });
    likedResposne.data.data.forEach(likedPost => {
      const postIndex = res.data.data.findIndex(post => post.id === likedPost.id);
      if (postIndex !== -1) {
        res.data.data[postIndex].isLikedByMe = true;
      }
   });
   let usersInfoAddedInPostData = res.data.data.map(item => {
    const userData = {
      userId: item.userId,
      email:item?.userName??'',
      role:item?.role,
      fullName:item?.fullName,
      profilePicture:item?.profilePicture
    }
    return {
        ...item,
        userData: userData
    };
  });    return dispatch({ type: GET_TRIAL_POSTS_SUCCESS, payload: usersInfoAddedInPostData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_TRIAL_POSTS_ERROR,
      payload: err
    });
  }
};
export const getPostByAnotherUser = (token,userId,id) => async (dispatch) => {
  try {
    dispatch({ type: GET_POSTS_BY_ANOTHER_USER_LOADING});
    const res = await apiRequest({ type: GET, endpoint: `posts/postsByUser/${id}`, token });
    const likedResposne = await apiRequest({ type: GET, endpoint: `posts/likespostById/${userId}`, token });
    likedResposne.data.data.forEach(likedPost => {
      const postIndex = res.data.data.findIndex(post => post.id === likedPost.id);
      if (postIndex !== -1) {
        res.data.data[postIndex].isLikedByMe = true;
      }
   });
   let usersInfoAddedInPostData = res.data.data.map(item => {
    const userData = {
      userId: item.userId,
      email:item?.userName??'',
      role:item?.role,
      fullName:item?.fullName,
      profilePicture:item?.profilePicture
    }
    return {
        ...item,
        userData: userData
    };
  });
  usersInfoAddedInPostData = usersInfoAddedInPostData.filter((el)=>!el.isTrial)
    return dispatch({ type: GET_POSTS_BY_ANOTHER_USER_SUCCESS, payload: usersInfoAddedInPostData});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: GET_POSTS_BY_ANOTHER_USER_ERROR,
      payload: err
    });
  }
};
export const createPostApi = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_POST_LOADING});
    const res = await apiRequest({ type: POST, endpoint: 'posts/createPost', token,data });
    dispatch(getPostByUser(token,data.userId));
    return dispatch({ type: CREATE_POST_SUCCESS, payload: res.data.message});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: CREATE_POST_ERROR,
      payload: err
    });
  }
};
export const deletePostApi = (token,id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_POST_LOADING});
    const res = await apiRequest({ type: DELETE, endpoint: `posts/deletePost/${id}`, token });
    return dispatch({ type: DELETE_POST_SUCCESS,payload:id});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: DELETE_POST_ERROR,
      payload: err
    });
  }
};
export const doLikeApi = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: LIKE_POST_LOADING});
    const res = await apiRequest({ type: POST, endpoint: 'posts/likePost', token,data });

    return dispatch({ type: LIKE_POST_SUCCESS, payload: data});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: LIKE_POST_ERROR,
      payload: err
    });
  }
};
export const doUnlikeApi = (token,data) => async (dispatch) => {
  try {
    dispatch({ type: UNLIKE_POST_LOADING});
    const res = await apiRequest({ type: POST, endpoint: 'posts/unLikePost', token,data });
    return dispatch({ type: UNLIKE_POST_SUCCESS, payload: data});
  } catch (err) {
    err = err.response?.data?.message
      ? err.response?.data?.message
      : err.message;
    dispatch(unAuthorisedApi(err));
    return dispatch({
      type: UNLIKE_POST_ERROR,
      payload: err
    });
  }
};