import {
  Avatar,
  HStack,
  Text,
  VStack,
  Image as ChakraImage,
  Box,
  Skeleton,
  useMediaQuery
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { FaRegCommentAlt } from "react-icons/fa";
import { GrLike } from "react-icons/gr";
import { LuShare2 } from "react-icons/lu";
import PostHeader from "./PostHeader";
import PostFooter from "./PostFooter";
import PostModal from "./PostModal";
import { AppContext } from "../context/AppContext";
import ImageSlider from "./ImageSlider";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { v4 } from "uuid";
import { useSelector } from "react-redux";

const Post = ({
  imageUrls = [],
  userData = {},
  caption,
  likesCount = 0,
  sharesCount = 0,
  commentsCount = 0,
  postId,
  isLikedByMe,
  videoUrl,
  postTime,
  leftOrRight
}) => {
  const { setPostModalData, setPostModalOpen, setCurrentPostCommentCount, setCommentModalOpen, } =
    useContext(AppContext);
    const [isLargerThan766] = useMediaQuery("(min-width: 766px)");
  const handlePostModal = (isCommentModal=false) => {
    !isCommentModal&&setPostModalOpen(true);
    setPostModalData({
      caption,
      likesCount,
      sharesCount,
      commentsCount,
      postId,
      imageUrls,
      userData,
      isLikedByMe,
      videoUrl,
      postTime
    });
    setCurrentPostCommentCount(Number(commentsCount));
  };

  return (
    <VStack
      align={"start"}
      p={"1rem 0"}
      w={["95%", "90%", "75%", "60%"]}
      borderBottom={"1px solid black"}
      borderTop={"1px solid black"}
      bgColor={'white'}
      data-aos={leftOrRight?"fade-right":"fade-left"}
    >
      <PostHeader
        postId={postId}
        fullName={userData.fullName}
        email={userData.email}
        role={userData.role}
        profilePicture={userData.profilePicture}
        isLikedByMe={isLikedByMe}
        userId={userData.userId}
        postTime={postTime}
      />
      <HStack justify={"center"} w={["100%"]} h={"100%"} align={"center"}>
        {(imageUrls.length || videoUrl?.length) && (
          <Splide
            options={{
              rewind: true,
              gap: "0.2rem",
              type: "fade",
              autoplay: false,
              arrows:
                imageUrls.length == 1 ||
                (imageUrls.length == 0 && videoUrl?.length)
                  ? false
                  : true,
              pagination: false,
              snap: false,
              speed: 1200,
              perPage: 1,
              perMove: 1,
              breakpoints: {
                640: {
                  perPage: 1,
                },
              },
            }}
          >
            {[...imageUrls, videoUrl ? videoUrl : ""]
              ?.filter((el) => el.length)
              ?.map((el, i) => (
                <SplideSlide
                  style={{ width: "100%"}}
                  key={v4()}
                >
                  <HStack
                    w={"100%"}
                    maxH={"70vh"}
                    justify={"center"}
                    minH={["45vh", "58vh", "60vh", "60vh"]}
                  >
                    {videoUrl?.length && i == imageUrls.length ? (
                      <>
                        <ChakraImage
                          h={"50%"}
                          w={["75%", "75%", "50%", "40%"]}
                          minH={"40vh"}
                          controls
                          src={videoUrl}
                          as={"video"}
                          onClick={()=>{
                            !isLargerThan766&&handlePostModal();
                          }}
                        />
                      </>
                    ) : (
                      <ChakraImage
                        w={"100%"}
                        objectFit={"cover"}
                        h={"100%"}
                        maxH={"100%"}
                        src={el}
                        onClick={()=>{
                          !isLargerThan766&&handlePostModal();
                        }}
                      />
                    )}
                  </HStack>
                </SplideSlide>
              ))}
          </Splide>
        )}
      </HStack>
      {!isLargerThan766 ? (
                <PostFooter
                  setCommentModalOpen={setCommentModalOpen}
                  isLikedByMe={isLikedByMe}
                  postId={postId}
                  likesCount={likesCount}
                  sharesCount={sharesCount}
                  commentsCount={Number(commentsCount)}
                  handlePostModal={handlePostModal}
                />
        ):
        <PostFooter
          handlePostModal={handlePostModal}
          isLikedByMe={isLikedByMe}
          postId={postId}
          likesCount={likesCount}
          sharesCount={sharesCount}
          commentsCount={commentsCount}
        />
        }
    </VStack>
  );
};

export default Post;
