import {
  Avatar,
  Button,
  HStack,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useEffect, useState } from "react";
import { RoleName } from "../utils/constant";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { themePrimaryColor } from "../utils/theme";
import {
  addFollower,
  addFollowing,
  getMyFollowing,
  getUserFollower,
} from "../store/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_USER_FOLLOWER_SUCCESS,
  UPDATE_USER_FOLLOWING_SUCCESS,
} from "../store/user/user.types";
const SuggestionCard = ({ userData }) => {
  const navigate = useNavigate();
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();
  const { profileData, myFollowing, searched_user_loading } = useSelector(
    (store) => store.userData
  );
  const { Token } = useSelector((store) => store.authData);
  useEffect(() => {
    if (userData.email?.length) {
      let followingOrNot = myFollowing?.some(
        (following) => following.id == userData.id
      );
      setIsFollowing(followingOrNot);
    }
  }, [myFollowing, userData]);
  const handleFollowButton = () => {
    if (!isFollowing) {
      setIsFollowing(true);
      dispatch(
        addFollowing(Token, {
          userId: profileData.id,
          followingId: userData.id,
        })
      ).then((res) => {
        if (res.type === UPDATE_USER_FOLLOWING_SUCCESS) {
          dispatch(
            addFollower(Token, {
              userId: userData.id,
              followerId: profileData.id,
            })
          ).then((res2) => {
            if (res2.type === UPDATE_USER_FOLLOWER_SUCCESS) {
              dispatch(getUserFollower(Token, userData.id));
            }
          });
          dispatch(getMyFollowing(Token, profileData.id));
        }
      });
    }
  };
  return (
    <>
      <SplideSlide style={{ width: "100%" }} key={v4()}>
        <VStack
          m={"auto"}
          w={["55%", "50%", "90%", "80%"]}
          border={`1px solid rgb(168, 168, 168)`}
          p={"0.5rem "}
          borderRadius={"0.3rem"}
          align={"center"}
          justify={"center"}
        >
          <Avatar
            cursor={"pointer"}
            onClick={() => {
              navigate(`/${userData.id}`);
            }}
            src={userData.profilePicture}
            width={["5rem", "5.5rem", "5.5rem", "6rem"]}
            h={["5rem", "5.5rem", "5.5rem", "6rem"]}
          />
          <VStack gap={"0"} w={"100%"} align={"center"} justify={"start"}>
            <Text
              fontSize={["0.8rem", "0.8rem", "0.9rem", "0.9rem"]}
              fontWeight={600}
            >
              {userData.fullName}
            </Text>
            <Text
              fontSize={["0.8rem", "0.8rem", "0.9rem", "0.9rem"]}
              fontWeight={600}
            >
              {RoleName(userData.role)}
            </Text>
          </VStack>

          <Button
            bgColor={themePrimaryColor}
            rounded={"5rem"}
            visibility={isFollowing?'hidden':'visible'}
            color={"white"}
            p={["0.4rem 1rem", "0.6rem 1rem", "0.68rem 1rem", "0.68rem 1rem"]}
            fontSize={["0.7rem", "0.7rem", "0.9rem", "0.9rem"]}
            h={"auto"}
            onClick={handleFollowButton}
          >
            Follow
          </Button>
        </VStack>
      </SplideSlide>
    </>
  );
};

export default SuggestionCard;